<template>
  <CRow>
    <CCol sm=12>
    <div id="map" ref="googleMap" 
      :style="{height:height}"
      style="margin:0 auto;">
    </div>
    </CCol>
  </CRow>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader';
import 'babel-polyfill';

// Map options (default by google)
const mapConfig = {
  // Set lat and lng from props (Important option)
  scaleControl: true,
  scrollwheel: true,
  // Set zoom from props (Important option)
  zoom: 14,
  streetViewControl: false,
  mapTypeControl: false,
  zoomControl: false,
  mapTypeId: 'roadmap',
  fullscreenControl: false,
  clickableIcons: false,
  // There u can add your custom styles
  // u can find some styles on https://mapstyle.withgoogle.com/
  styles: []
}

export default {
  props : {
      address : {type:String, default:"서울특별시 금천구 가산디지털1로 212 코오롱 디지털타워"},
      apiKey : {type:String, default:'AIzaSyBNZY2RbM_Ti2gWPsVfhJiUpMseMlX6NxA'},
      width : {type:String, default:'420px'},
      height : {type:String, default:'250px'},
      lat : {type:Number, default:37.484315},
      lng : {type:Number, default:126.880516},
      zoom : {type:Number, default:14},
      draggable : {type:Boolean, default:true},
      search : {type:Boolean, default:true}, // address search 여부 옵션
      onlyshow : {type:Boolean, fefault : false}, // 맵에 입력 없이, 오직 보기만 설정
      markers_info : {type:Array, default : function(){return []}}, // ex. [{lat:12,lng:24}, ...]
  },
  data(){
    return {
      mapConfig : {...mapConfig},
      google:null,
      map:null,
      marker:null,  // current marker, Google Marker - object
      markers:null, // setted markers, Array
      is_draging:false,
    }
  },
  created() {
    this.mapConfig.zoom = this.zoom;
    this.initializeMap();
  },
  computed :{
    center : function(){
      return {lat:this.lat, lng:this.lng};
    },
  },
  watch : {
    lat : function(){
      if(!this.is_draging && this.marker){ 
        this.moveMarker()
      }
    },
    lng : function(){
      if(!this.is_draging && this.marker){
        this.moveMarker()
      }
    },
    markers_info : function(){
      if(this.markers===null){
        return;
      }
      // 각 marker delete 
      this.removeMarkers();
      this.settingMarkers();
    },
  },

  methods:{
    initGoogle(){
      return new Promise((resolve, reject)=>{
        GoogleMapsApiLoader({apiKey:this.apiKey})
        .then((google)=>{
          resolve(google);
        })
      })
    },

    async initializeMap(){
      this.google = await this.initGoogle();
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(mapContainer, {
         center: this.center, zoom: this.mapConfig.zoom, disableDefaultUI:true,
         zoomControl:true,});
      
      this.settingMarkers();
      if(this.onlyshow) // onlyshow 모드 시에는 설정된 마커들만 설정,표시하고 종료
        return;
      if(this.search){ // Address Search 모드 : Address를 받아서 지도 검색 후 표현
        this.searchMapByAddress();
      }else{ // LatLng 모드 : Lat Lng을 받아서 지도 표현 (주소 검색 지원 X)
        this.searchMapByLatLng();
      }
    },

    settingMarkers(){
      this.markers = [];
      this.markers_info.filter((info)=>{
        let mark_position = {lat:info.lat, lng:info.lng}
        let marker = new this.google.maps.Marker({
          map:this.map,
          draggable:false,
          title:String(info.id),
          position:mark_position
        });
        this.map.setCenter(mark_position);

        marker.addListener('click', ()=>{
          this.clickMarker(marker.title);
        });
        this.markers.push(marker); // markers setting
      });
    },
    removeMarkers(){
      this.markers.filter((marker)=>{
        marker.setMap(null); // remove marker from map
        marker = null; // delete
        // https://developers.google.com/maps/documentation/javascript/markers
      })
    },
    searchMapByAddress(){
      const geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({address:this.address},(results, status)=>{
        if (status !== "OK") {
          console.warn("주소 오류. 기본 위치 설정.");
          const lat = 37.484432;
          const lng = 126.880592;
          this.map.setCenter({lat:lat, lng:lng});
          let marker = new this.google.maps.Marker({
            map:this.map,
            draggable:this.draggable,
            position : {lat:lat, lng:lng}
          })
          marker.addListener('drag', this.dragStartMarker);
          marker.addListener('dragend', this.dragEndMarker);
          this.marker = marker;
          this.$emit('updatedLatLng', {lat:lat, lng:lng});
        } else {
          let lat = results[0].geometry.location.lat();
          let lng = results[0].geometry.location.lng();
          this.map.setCenter({lat:lat, lng:lng});
          let marker = new this.google.maps.Marker({
            map:this.map,
            draggable : this.draggable,
            position:results[0].geometry.location,
          })
          marker.addListener('drag', this.dragStartMarker);
          marker.addListener('dragend', this.dragEndMarker);
          this.marker = marker;
          this.$emit('updatedLatLng', {lat:lat, lng:lng});
        }
      })
    },
    searchMapByLatLng(){
      let marker = new this.google.maps.Marker({
        map:this.map,
        position:this.center,
        draggable : this.draggable,
      });
      if(this.draggable){
        marker.addListener('drag', this.dragStartMarker);
        marker.addListener('dragend', this.dragEndMarker);
        this.$emit('updatedLatLng', {lat:this.lat, lng:this.lng});
      }
      this.marker = marker;
    },
    dragStartMarker(event){
      this.is_draging = true;
      let lat = event.latLng.lat();
      let lng = event.latLng.lng();
      this.$emit('updatedLatLng', {lat:lat, lng:lng});
    },
    dragEndMarker(event){
      let lat = event.latLng.lat();
      let lng = event.latLng.lng();
      this.$emit('updatedLatLng', {lat:lat, lng:lng});
      this.is_draging = false;
    },
    clickMarker(region_id){
      this.$emit('click_region', region_id);
    },
    moveMarker(){
      this.map.setCenter(this.center);
      this.marker.setPosition(new google.maps.LatLng(this.lat, this.lng));
    },
    focusingMap(lat, lng){
      // console.log("focusing Map .. {"+lat+","+lng+"}")
      this.map.setCenter({lat:lat, lng:lng});
    },
  }
    
}
</script>